.chart-placeholder {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart-wrapper {
    width: 100%;
    height: 500px;
}