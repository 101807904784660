.layout-header {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    position: fixed !important;
    z-index: 100;

    >div {
        width: 100%;
    }
}

.bottom-bar-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    z-index: 100;
    border-top: 1px solid #ffffff6e;

    .bottom-bar {
        background-color: rgb(4 21 40);
        height: 60px;

        &-button {
            border: none;
            padding: 0;

            &-icon {
                color: white;
                padding: 0;
                font-size: 20px;
            }

            &-text {
                color: white;
                padding: 0;
                font-size: 12px;
            }

            &.active {
                .bottom-bar-button-icon {
                    color: rgb(245 130 47) !important;
                }

                .bottom-bar-button-text {
                    color: rgb(245 130 47) !important;
                }
            }
        }
    }
}