@media only screen and (min-width: 530px) {
    .ant-drawer-right > .ant-drawer-content-wrapper {
        min-width: 450px !important;
    }    
}

.date-picker{
    width: 100%;
}

.scanner-modal {

    &.ant-modal,
    .ant-modal-content {
        height: 100vh;
        width: 100vw;
        margin: 0;
        top: 0;
    }

    .ant-btn-primary {
        width: 100%;
    }

    .ant-modal-body {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
    }
}