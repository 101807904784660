.fullscreen-loader {
    width: 100vw;
    height: 100vh;

    >.ant-col {
        margin: auto;
    }

    .progress .ant-progress-bg {
        background-color: #ec863c;
        height: 6px !important;
    }
}