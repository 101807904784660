.layout-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed !important;
    z-index: 100;

    > div {
        width: 100%;
    }
}