.mobile-action-wrapper {
    width: 100% !important;
    border: 1px solid lightgray;

    div>button {
        width: 100% !important;
        color: black !important;
    }
}

.mobile-actions {
    color: black !important;
}

.action-dropdown ul.ant-dropdown-menu li.ant-dropdown-menu-item {
    padding: 0px;
  }   