.layout {
    min-height: 100vh !important;

    &-sider {
        overflow: auto;
        height: 100vh;
        position: fixed !important;
        left: 0;
        top: 0;
        bottom: 0;
        padding-top: 60px;
        z-index: 99;

        .ant-menu {
            padding-bottom: 60px;
        }
    }

    .mobile-sider {
        max-width: 100% !important;
        width: 100% !important;
    }

    &-content {
        margin-left: 200px;
        margin-top: 64px;
        padding: 24px;
        overflow: auto;

        &-collapsed {
            margin-top: 64px;
            padding: 24px;
            overflow: auto;
        }
    }

    &-footer {
        text-align: center;
        margin-left: 144px;

        &-collapsed {
            text-align: center;
            margin-left: 24px;
        }
    }
}