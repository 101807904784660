/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ql-editor {
  overflow-y: scroll;
  resize: vertical;
  min-height: 20em;
  width: 100%;
}
.ql-snow.ql-toolbar {
  display: block;
  background: #e6fffb;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}
@media screen and (min-width: 768px) {
  .ant-drawer .ql-editor {
    max-width: 400px;
  }
}
.ql-tooltip {
  left: 0 !important;
}
