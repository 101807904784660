@media screen and (max-width: 576px) {
    .ant-layout-header {
        padding: 0 8px !important;
    }

    .layout-content-collapsed {
        padding-top: 12px;
    }

    .ant-card-head-title {
        padding: 12px 0 !important;
    }
}

.mobile-header {
    &-button {
        height: auto !important;
    }
    &-text {
        color: white !important;
        font-size: 20px;
        padding: 10px;
    }
}

