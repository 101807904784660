.login {
    .container {
        min-height: 100vh !important;

        .card {
            padding: 20px;

            .login-button {
                width: 100%;
            }
        }
    }

    .layout-header-login {
        background: #f0f2f5;
    }
}

@media screen and (max-width: 576px) {
    .login {
        .container {
            .card {
                width: 100vw;
            }
        }

        &.ant-layout  {
            background: white;
        }

        .layout-header-login {
            background: white;
        }
    }
}


@media screen and (min-width: 576px) {
    .ant-card-body {
        min-width: 350px;
    }
}